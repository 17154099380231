// icons from https://thenounproject.com/search/icons/?q=

import various from '../assets/various.png';
import arms from '../assets/arms.png';
import biceps from '../assets/biceps.png';
import triceps from '../assets/triceps.png';
import shoulder from '../assets/shoulder.png';
import back from '../assets/back.png';
import traps from '../assets/traps.png';
import chest from '../assets/chest.png';
import legs from '../assets/legs.png';
import quads from '../assets/quads.png';
import glutes from '../assets/glutes.png';
import abs from '../assets/abs.png';
import lowback from '../assets/lowback.png';
import forearms from '../assets/forearms.png';
import calves from '../assets/calves.png';
import hamstrings from '../assets/hamstrings.png';

import dumbbells from '../assets/dumbbells.png';
import kettlebell from '../assets/kettlebell.png';
import barbell from '../assets/barbell.png';
import ball from '../assets/ball.png';
import fixedbar from '../assets/fixedbar.png';
import dumbbellbar from '../assets/dumbbellbar.png';
import weightplates from '../assets/weightplates.png';
import fixedbench from '../assets/fixedbench.png';
import adjustablebench from '../assets/adjustablebench.png';
import pullupbar from '../assets/pullupbar.png';
import station from '../assets/station.png';
import bands from '../assets/bands.png';
import trainingbands from '../assets/trainingbands.png';
import exerciseball from '../assets/exerciseball.png';
import mat from '../assets/mat.png';

export const equipmentsTemplate = [
  'Somente o Corpo',
  'Halteres',
  'Kettlebell',
  'Barra de Supino',
  'Bola de Peso',
  'Barra de Peso Fixo',
  'Barra de Anilhas Ajustáveis',
  'Anilhas de Peso',
  'Banco de Musculação Fixo',
  'Banco de Musculação Ajustável',
  'Barra de Porta',
  'Estação de Musculação',
  'Faixas de Resistência',
  'Elásticos de Treinamento',
  'Bola de Exercício',
  'Tapete de Exercícios',
];

export const equipmentsTemplateEn = [
  'Bodyweight Only',
  'Dumbbells',
  'Kettlebell',
  'Barbell',
  'Weight Ball',
  'Fixed Weight Bar',
  'Adjustable Dumbbell Bar',
  'Weight Plates',
  'Fixed Weight Bench',
  'Adjustable Weight Bench',
  'Door Bar',
  'Strength Training Station',
  'Resistance Bands',
  'Training Bands',
  'Exercise Ball',
  'Exercise Mat',
];

export const muscularGroupsTemplate = [
  'Variados',
  'Braços',
  'Bíceps',
  'Tríceps',
  'Antebraços',
  'Ombros',
  'Costas',
  'Trapézio',
  'Lombar',
  'Peito',
  'Pernas',
  'Quadríceps',
  'Posterior de Coxa',
  'Glúteos',
  'Panturrilhas',
  'Abdominais',
];

export const muscularGroupsTemplateEn = [
  'Various',
  'Arms',
  'Biceps',
  'Triceps',
  'Forearms',
  'Shoulders',
  'Back',
  'Traps',
  'Lower Back',
  'Chest',
  'Legs',
  'Quads',
  'Hamstrings',
  'Glutes',
  'Calves',
  'Abdominals',
];

export const muscularGroupIcons = [
  various,
  arms,
  biceps,
  triceps,
  forearms,
  shoulder,
  back,
  traps,
  lowback,
  chest,
  legs,
  quads,
  hamstrings,
  glutes,
  calves,
  abs,
];

export const equipmentIcons = [
  various,
  dumbbells,
  kettlebell,
  barbell,
  ball,
  fixedbar,
  dumbbellbar,
  weightplates,
  fixedbench,
  adjustablebench,
  pullupbar,
  station,
  bands,
  trainingbands,
  exerciseball,
  mat,
];

export const trainingNames = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];
